import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Flex from "../atoms/Flex"
import FilterTabs from "../molecules/FilterTabs"
import FilterDropdown from "../molecules/FilterDropdown"

const Filter = ({
  id,
  name,
  allLabel,
  location,
  tags,
  onChange,
  gridItems,
  alignMenuItems,
  showFilterLabel,
  layout,
  dropdownCard,
  searchedTerm,
  filterPriority,
  ...rest
}) => {
  const [selected, setSelected] = useState({ [id]: allLabel })
  const [menuOpen, setMenuOpen] = useState(false)
  const [options, setOptions] = useState([])
  const anchorRef = useRef(null)

  const conditionalProps = {}
  switch (location) {
    case "right":
      conditionalProps.ml = 7
      break
    case "splitReverse":
      conditionalProps.ml = { _: 5, md: 7 }
      conditionalProps.mr = { _: 5, md: 0 }
      break
    case "center":
      conditionalProps.mx = 5
      break
    case "left":
      conditionalProps.mr = 7
      break
    case "split":
      conditionalProps.mr = { _: 5, md: 7 }
      conditionalProps.ml = { _: 5, md: 0 }
      break
    default:
      conditionalProps.mr = 7
  }

  useEffect(() => {
    if (
      filterPriority === "searchOnly" &&
      searchedTerm?.length > 0 &&
      selected?.[id] !== allLabel
    ) {
      setSelected({ [id]: allLabel }) //deselect active filter tags when user type in search bar and mode is set to searchOnly
    }

    onChange(selected?.[id] === allLabel ? null : selected)
  }, [filterPriority, searchedTerm, selected])

  useEffect(() => {
    let newOptions = []
    if (allLabel) newOptions = [allLabel]
    if (tags) {
      const sortedTags = [...tags].sort((a, b) => {
        if (a.position === null || a.position === undefined) return 0 // this ensure old site tags order will stay the same
        if (a.position < b.position) return -1
        if (a.position > b.position) return 1

        return 0
      }) // sorting tags by position and name ascending
      newOptions = [...newOptions, ...sortedTags.map(({ name }) => name)]
    }
    setOptions(newOptions)
  }, [allLabel, tags])

  useEffect(() => {
    if (!menuOpen && selected?.[id] !== allLabel) {
      setMenuOpen(false) // automatically close the menu when a selection is made
    }
  }, [selected, menuOpen, id, allLabel])

  useEffect(() => {
    setSelected({ [id]: allLabel })
  }, [allLabel])

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      mb={4}
      {...conditionalProps}
    >
      {layout === "tabs" ? (
        <FilterTabs
          allLabel={allLabel}
          menuOpen={menuOpen}
          options={options}
          anchorRef={anchorRef}
          showFilterLabel={showFilterLabel}
          alignMenuItems={alignMenuItems}
          location={location}
          selected={selected}
          name={name}
          id={id}
          setSelected={setSelected}
          setMenuOpen={setMenuOpen}
          {...rest}
        />
      ) : (
        <FilterDropdown
          allLabel={allLabel}
          menuOpen={menuOpen}
          options={options}
          anchorRef={anchorRef}
          showFilterLabel={showFilterLabel}
          alignMenuItems={alignMenuItems}
          location={location}
          selected={selected}
          name={name}
          id={id}
          dropdownCard={dropdownCard}
          setSelected={setSelected}
          setMenuOpen={setMenuOpen}
          {...rest}
        />
      )}
    </Flex>
  )
}

Filter.strapiProps = {
  name: PropTypes.string,
  allLabel: PropTypes.string.isRequired,
  showFilterLabel: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
}

export default Filter

export const query = graphql`
  fragment Filter on STRAPI__COMPONENT_ATOMS_FILTER {
    id
    name
    allLabel
    showFilterLabel
    layout
    tags {
      name
      createdAt
      position
    }
  }
`
