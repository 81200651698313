import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { compareDesc, compareAsc } from "date-fns"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"

import FilteredGrid from "../molecules/FilteredGrid"
import Post from "../molecules/Post"
import SectionTitle from "../molecules/SectionTitle"
import useBreakpoint from "../../hooks/use-breakpoint"

const defaultCard = {
  borderRadius: "md",
  boxShadow: "md",
  bg: 0,
  theme: "default",
  useCard: true,
}

const BlogGrid = ({
  section,
  sectionTitle,
  flexGrid,
  posts,
  card,
  showDate,
  filter,
  BGFilter,
  filters,
  BGFilters,
  sort,
}) => {
  showDate = showDate === null ? true : showDate //setting default value for older sites
  card = card === null ? defaultCard : card //setting default value for older sites
  filter = BGFilter || filter
  filters = BGFilters || filters

  const center = sectionTitle.align === "center"
  const { columns, itemsDisplayedDesktop, itemsDisplayedMobile } = flexGrid

  // Logic to calculate how many items to load before Load More button
  const breakpointsOrdered = [
    { _: false },
    { xs: useBreakpoint("xs") },
    { sm: useBreakpoint("sm") },
    { md: useBreakpoint("md") },
    { lg: useBreakpoint("lg") },
    { xl: useBreakpoint("xl") },
  ]
  const columnsBpOrdered = breakpointsOrdered.filter((bp, idx) =>
    Object.keys(columns).includes(Object.keys(bp)[0])
  )

  let currentColumns
  for (let i = 0; i < columnsBpOrdered.length; i++) {
    // figure out current number of columns using breakpoint
    const isBelowBp = Object.values(columnsBpOrdered[i])[0]
    const prevIdx = i >= 1 ? i - 1 : 0
    const lastIdx = columnsBpOrdered.length - 1
    if (isBelowBp) {
      const currentBp = Object.keys(columnsBpOrdered[prevIdx])[0]
      currentColumns = columns[currentBp]
      break
    } else {
      const currentBp = Object.keys(columnsBpOrdered[lastIdx])[0]
      currentColumns = columns[currentBp]
    }
  }

  let numToLoad
  const isMobileBp = breakpointsOrdered[3]["md"]
  if (isMobileBp && itemsDisplayedMobile) {
    numToLoad = itemsDisplayedMobile
  } else if (itemsDisplayedDesktop) {
    numToLoad = itemsDisplayedDesktop
  } else {
    // if itemsDisplayed values are null (i.e. old sections)
    // assign recommended numToLoad based on number of columns
    switch (currentColumns) {
      case 1:
      case 2:
        numToLoad = 6
        break
      case 3:
        numToLoad = 9
        break
      case 4:
        numToLoad = 16
        break
      case 5:
        numToLoad = 30
        break
      default:
        numToLoad = 6
    }
  }

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        flexGrid={flexGrid}
        filters={filters}
        filtersStyles={{
          filterLocation: filter?.filterLocation,
          alignMenuItems: filter?.alignMenuItems,
          showFilterLabel: filter?.showFilterLabel,
          layout: filter?.layout,
          dropdownCard: filter?.dropdownCard,
        }} // only sending style fields to avoid possibly overwriting filter data
        card={{
          theme: section.theme,
          bg: section.bg,
          borderRadius: "md",
          // boxShadow: "md",
          boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
        }}
        loadMoreBtnStyles={{
          alignItems: center ? "center" : "flex-start",
        }}
        numToLoad={numToLoad}
        currentColumns={currentColumns}
      >
        {posts
          ?.slice()
          .sort((a, b) =>
            sort === "newest"
              ? compareDesc(new Date(a.date), new Date(b.date))
              : sort === "oldest"
              ? compareAsc(new Date(a.date), new Date(b.date))
              : null
          )
          .map((post, i) => (
            <Post
              {...post}
              showDate={showDate}
              align={sectionTitle.align}
              key={i}
              card={card}
            />
          ))}
      </FilteredGrid>
    </Section>
  )
}

export default BlogGrid

BlogGrid.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape(Post.strapiProps)),
  sort: PropTypes.oneOf(["newest", "oldest", "manual"]),
  ...FilteredGrid.strapiProps,
}

BlogGrid.propTypes = {
  ...BlogGrid.strapiProps,
}

export const query = graphql`
  fragment BlogGrid on STRAPI__COMPONENT_SECTIONS_BLOG_GRID {
    strapi_id
    BLOGGRIDSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    posts {
      ...Post
    }
    card {
      ...Card
    }
    BGFilters: filters {
      ...Filter
    }
    BGFilter: filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      dropdownCard {
        ...Card
      }
    }
    showDate
    sort
  }
`
